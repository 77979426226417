
import {h, VNode} from 'vue'

const SIZES = [400, 800, 1300, 1600, 1920, 2600, 3000]

export type MsImageProps = {
    id: number
    sizes?: string
}

const MsImage = ({id, sizes}: MsImageProps): VNode | VNode[] => {
    const srcset = SIZES.map((size) => `/static/img/xmas-${id}-${size}.jpg ${size}w`).join(', ')

    return h(
        'div',
        {class: 'img-wrapper'},
        h('img', {
            srcset,
            src: `/static/img/xmas-${id}-1920.jpg`,
            sizes,
            width: 1920,
            height: 1080,
        }),
    )
}
MsImage.props = {
    id: {type: Number, required: true},
    sizes: {type: String},
}
export default MsImage
