<template>
    <div class="home">
        <div class="head text-content">
            <h1>
                Geht ihr nicht raus,<br />
                kommt die Welt zu euch nach Haus'
            </h1>
            <p class="introduction">
                Die Idee hinter dem diesjährigen Weihnachtsgeschenk ist simpel: Warum nicht die Welt
                ein wenig nach Hause holen, wenn man selbst nicht verreisen kann?
            </p>
            <p>
                So erwartet euch in dem Paket ein kleines Sammelsorium an Süßigkeiten aus mehr oder
                weniger fernen Ländern. Um diese richtig zuzordnen, gibt's diese kleine
                Informationsseite. Leider ist sie noch nicht fertig, daher ist das hier nur ein
                erster schneller Wurf, der nach und nach aufgehübscht wird. Vielleicht kommen auch
                noch Infos dazu.
            </p>
        </div>

        <div class="links">
            <ms-loader :loading="!sweets">
                <router-link
                    v-for="sweet in sweets"
                    :key="`sweet-link-${sweet.id}`"
                    :to="{name: 'detail', params: {id: sweet.id}}"
                >
                    <ms-image
                        :id="sweet.images[0]"
                        sizes="(max-width: 576px) 100vw, (max-width: 992px) 50vw, (max-width: 1800px) 33.333vw, (min-width: 1800.2px) 16.667vw"
                    />
                </router-link>
            </ms-loader>
        </div>
    </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'

import MsLoader from '@/components/MsLoader.vue'
import MsImage from '@/components/MsImage.vue'

import {getSweets} from '@/helper/data'
import type {Sweet} from '@/helper/data'

@Options({
    components: {
        MsLoader,
        MsImage,
    },
})
export default class Home extends Vue {
    sweets: Sweet[] | null = null

    async mounted(): Promise<void> {
        this.sweets = await getSweets()
    }
}
</script>

<style lang="sass" scoped>
.home
    display: grid
    grid-template-rows: auto min-content
    align-items: center
    min-height: 100vh

.head
    padding: 3em 0 5em

.introduction
    @extend %font-introduction

.links
    display: grid
    grid-template-columns: 1fr

    +breakpoint-up(sm)
        grid-template-columns: 1fr 1fr

    +breakpoint-up(lg)
        grid-template-columns: repeat(3, 1fr)

    +breakpoint-up(1800px)
        grid-template-columns: repeat(6, 1fr)
</style>
