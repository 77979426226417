import axios from 'axios'

export type Country = {
    id: number
    name: string
}

export type Sweet = {
    id: number
    name: string
    images: number[]
    description: string[]
    ingredients: string
    comments?: string[]
    country: Country
    mhd?: Date
    links?: string
}

type RawData = {
    countries: Country[]
    sweets: Array<Omit<Sweet, 'country' | 'mhd'> & {country: number; mhd: string}>
}

type Data = {countries: Country[]; sweets: Sweet[]}
let data: Data | null = null
async function getData(): Promise<Data> {
    if (!data) {
        const {
            data: {countries, sweets},
        } = await axios.get<RawData>('static/data.json')
        data = {
            countries,
            sweets: sweets.map((sweet) => ({
                ...sweet,
                country: countries.find(({id}) => id === sweet.country) as Country,
                mhd: new Date(sweet.mhd),
            })),
        }
    }
    return data
}

export async function getSweets(): Promise<Sweet[]> {
    return (await getData()).sweets
}

export async function getSweet(searchId: number): Promise<Sweet | undefined> {
    return (await getSweets()).find(({id}) => id === searchId)
}

export async function getCountries(): Promise<Country[]> {
    return (await getData()).countries
}

export async function getCountry(searchId: number): Promise<Country | undefined> {
    return (await getCountries()).find(({id}) => id === searchId)
}
