
import {Options, Vue} from 'vue-class-component'

import MsLoader from '@/components/MsLoader.vue'
import MsImage from '@/components/MsImage.vue'

import {getSweets} from '@/helper/data'
import type {Sweet} from '@/helper/data'

@Options({
    components: {
        MsLoader,
        MsImage,
    },
})
export default class Home extends Vue {
    sweets: Sweet[] | null = null

    async mounted(): Promise<void> {
        this.sweets = await getSweets()
    }
}
