
import {h, VNode} from 'vue'
import SvgSprite from './SvgSprite.vue'

export type MsLoaderProps = {
    loading: boolean
}

const MsLoader = ({loading}: MsLoaderProps, {attrs, slots}: any): VNode | VNode[] => {
    return loading
        ? h(SvgSprite, {...attrs, icon: 'loading', class: 'spin loader'})
        : slots.default()
}
MsLoader.props = {
    loading: {type: Boolean, required: true},
}
export default MsLoader
